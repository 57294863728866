<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="الموافقة على إرسال التحذير"
      hide-footer
      :no-close-on-backdrop="true"
      :clickToClose="false"
    >
      <b-card-text>
        <h4 class="text-center">
          <img
            :src="require('@/assets/images/alert.png')"
            class="g c"
            style="width: 100px"
          />
          <br />
          طلب المشرف <span class="text-danger">{{ alert.admin }}</span>
          <br />
          إرسال تنبيه عام لأولياء الأمور لدى خط السير
          <span class="text-danger">{{ alert.group.title }}</span> في الباص
          <span class="text-danger">{{ alert.bus.title }}</span>
          <br />
          <q>{{ alert.details }}</q>
        </h4>
        <hr />
        <div class="form-group">
          <label for="">محتوى التنبيه</label>
          <textarea
            class="form-control"
            name=""
            id=""
            rows="3"
            v-model="alert.details"
          ></textarea>
        </div>
        <hr />
        <div class="row">
          <div class="col-6 g text-center">
            <button class="btn btn-success c" @click="confirmAlert()">
              <i class="fa fa-paper-plane"></i>
              إرسال التنبيه
            </button>
          </div>
          <div class="col-6 g text-center">
            <button class="btn btn-primary c" @click="rejectAlert()">
              <i class="fa fa-times"></i>
              عدم الإرسال
            </button>
          </div>
        </div>
      </b-card-text>
    </b-modal>
    <b-button style="display: none" v-b-modal.modal-1 ref="gg"> </b-button>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { BModal, BButton, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  data() {
    return {
      alert: {
        bus: {},
        group: {},
        busses: [],
      },
    };
  },
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BCardText,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    var _g = this,
      g = this;
    var i = setInterval(() => {
      var user = localStorage.getItem("user");
      if (!user) {
        if (
          window.location.pathname != "/login" &&
          window.location.pathname != "/logout" &&
          window.location.pathname != "/error-404"
        ) {
          clearInterval(i);
          _g.$router.push("/login");
        }
      } else {
        clearInterval(i);
        $.post(api + "/admin/auth/check", {
          jwt: JSON.parse(user).jwt,
        })
          .then(function (data) {
            data = JSON.parse(data);
            if (data.status != 100 && data.status != 300) {
              _g.$router.push("/logout");
            } else {
              localStorage.setItem("user", JSON.stringify(data.response));
              $.post(api + "/admin/bus/list", {
                jwt: JSON.parse(user).jwt,
              }).then(function (r) {
                g.busses = JSON.parse(r).response;
              });
              if (data.response.app_devices_pending > 0) {
                setTimeout(() => {
                  $("#app_devices_pending").html(
                    `<span class='badge bg-danger'><i class='fa fa-mobile'></i> ${data.response.app_devices_pending}</span>`
                  );
                  $("#app_devices_pending").click(function () {
                    window.location = "/parents/devices";
                  });
                }, 1000);
              }
              // loggedin
              g.sockets.subscribe("notification", (d) => {
                if (
                  d.includes("__confirm_alert") &&
                  !JSON.parse(user).all_rules
                ) {
                  g.checkAlerts();
                  return false;
                }
                if (d.includes("__confirm_alert")) {
                  g.checkAlerts();
                }
                var view = false;
                if (JSON.parse(user).all_rules) {
                  view = true;
                } else {
                  g.busses.forEach((element) => {
                    if (d.includes(element.title)) {
                      view = true;
                    }
                  });
                }
                if (view) {
                  var audio = new Audio(
                    "https://cdn-static.brmja.com/storage/uploads/files/620511d8d5536534353593.mp3"
                  );
                  audio.play();
                  if (window.location.pathname == "/") {
                    g.$router.push("/parents/add");
                    setTimeout(() => {
                      g.$router.push("/");
                    }, 100);
                  }
                  g.$toast({
                    component: ToastificationContent,
                    props: {
                      title: d.replaceAll("__confirm_alert", ""),
                      icon: "BellIcon",
                      variant: "success",
                    },
                  });
                }
              });
              g.checkAlerts();
              // end loggedin
            }
          })
          .catch(function () {
            _g.$router.push("/logout");
          });
      }
    }, 100);

    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
  methods: {
    confirmAlert() {
      if (confirm("متأكد من الإرسال؟")) {
        var g = this;
        var user = localStorage.getItem("user");
        user = JSON.parse(user);
        $.post(api + "/admin/bus/accept-alert", {
          jwt: user.jwt,
          group_id: g.alert.group._id,
          id: g.alert._id,
          details: g.alert.details,
        })
          .then(function (r) {
            alert("جاري الآن الإرسال..");
            location.reload();
          })
          .catch(function (r) {
            alert("حدث خطأ");
          });
      }
    },
    rejectAlert() {
      if (confirm("متأكد من الإلغاء؟")) {
        var g = this;
        var user = localStorage.getItem("user");
        user = JSON.parse(user);
        $.post(api + "/admin/bus/reject-alert", {
          jwt: user.jwt,
          id: g.alert._id,
        })
          .then(function (r) {
            location.reload();
          })
          .catch(function (r) {
            alert("حدث خطأ");
          });
      }
    },
    checkAlerts() {
      var g = this;
      var user = localStorage.getItem("user");
      user = JSON.parse(user);
      $.post(api + "/admin/bus/alerts", {
        jwt: user.jwt,
      }).then(function (r) {
        r = r ? JSON.parse(r) : false;
        if (r) {
          g.alert = r;
          g.$refs.gg.click();
        }
      });
    },
  },
};
</script>
