import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/import-students',
      name: 'import-students',
      component: () => import('@/views/students/import.vue')
    },
    {
      path: '/parents/add',
      name: 'parents-add',
      component: () => import('@/views/parents/add.vue')
    },
    {
      path: '/parents/view',
      name: 'parents-view',
      component: () => import('@/views/parents/view.vue')
    },
    {
      path: '/parents/devices',
      name: 'parents-devices',
      component: () => import('@/views/parents/devices.vue')
    },
    {
      path: '/parents/edit/:id',
      name: 'parents-edit',
      component: () => import('@/views/parents/edit.vue')
    },
    {
      path: '/parents/overview/:id',
      name: 'parents-overview',
      component: () => import('@/views/parents/overview.vue')
    },
    {
      path: '/students/view',
      name: 'view-students',
      component: () => import('@/views/students/view.vue')
    },
    {
      path: '/students/edit/:id',
      name: 'edit-students',
      component: () => import('@/views/students/edit.vue')
    },
    {
      path: '/students/overview/:id',
      name: 'overview-students',
      component: () => import('@/views/students/overview.vue')
    },
    {
      path: '/students/add',
      name: 'add-students',
      component: () => import('@/views/students/add.vue')
    },
    {
      path: '/students/auto-parents',
      name: 'auto-parents',
      component: () => import('@/views/students/auto-parents.vue')
    },
    {
      path: '/students/move',
      name: 'move-students',
      component: () => import('@/views/students/move.vue')
    },
    {
      path: '/groups/add',
      name: 'groups-add',
      component: () => import('@/views/groups/add.vue')
    },
    {
      path: '/groups/list',
      name: 'groups-view',
      component: () => import('@/views/groups/list.vue')
    },
    {
      path: '/groups/edit/:id',
      name: 'groups-edit',
      component: () => import('@/views/groups/edit.vue')
    },
    {
      path: '/bus/edit/:id',
      name: 'bus-edit',
      component: () => import('@/views/bus/edit.vue')
    },
    {
      path: '/bus/add',
      name: 'bus-add',
      component: () => import('@/views/bus/add.vue')
    },
    {
      path: '/bus/list',
      name: 'bus-view',
      component: () => import('@/views/bus/list.vue')
    },
    {
      path: '/bus/archive',
      name: 'archive',
      component: () => import('@/views/bus/archive.vue')
    },
    {
      path: '/bus/admins',
      name: 'bus-admins',
      component: () => import('@/views/bus/admins.vue')
    },
    {
      path: '/bus/admins/add',
      name: 'bus-admins-add',
      component: () => import('@/views/bus/admins-add.vue')
    },
    {
      path: '/bus/admins/edit/:id',
      name: 'bus-admins-edit',
      component: () => import('@/views/bus/admins-edit.vue')
    },
    {
      path: '/admins/add',
      name: 'admins-add',
      component: () => import('@/views/admins/add.vue')
    },
    {
      path: '/admins/list',
      name: 'admins-view',
      component: () => import('@/views/admins/list.vue')
    },
    {
      path: '/admins/edit/:id',
      name: 'admins-edit',
      component: () => import('@/views/admins/edit.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/web/settings.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/web/profile.vue')
    },
    {
      path: '/noti',
      name: 'noti',
      component: () => import('@/views/web/noti.vue')
    },
    {
      path: '/logs',
      name: 'logs',
      component: () => import('@/views/web/logs.vue')
    },
    {
      path: '/sections',
      name: 'sections',
      component: () => import('@/views/sections/index.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/logout.vue')
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/no-per',
      name: 'no-per',
      component: () => import('@/views/error/no-per.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-404-r',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
