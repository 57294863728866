import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from "socket.io-client"

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api = process.env.VUE_APP_API

//
window.checkPer = (g, per, type) => {
  var user = localStorage.getItem("user");
  user = JSON.parse(user);
  if (!user.all_rules) {
    if (user.rules[per]) {
      if (user.rules[per].allowed) {
        for (const [key, value] of Object.entries(user.rules[per])) {
          if (key == type && value != 'true') {
            g.$router.push('/no-per')
            return false;
          }
        }
      } else {
        g.$router.push('/no-per')
        return false;
      }
    } else {
      g.$router.push('/no-per')
      return false;
    }
  } else {
    return true;
  }
  return true;
}
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
import 'vue-select/dist/vue-select.css';

// Composition API
Vue.use(VueCompositionAPI)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(new VueSocketIO({
  debug: false,
  connection: SocketIO(process.env.VUE_APP_API, {
    auth: {
      user: localStorage.getItem("user"),
      type: "admin"
    }
  })
}))

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
